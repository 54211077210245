<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card card-custom card-sticky">
          <notifications group="notification" position="top right" />
          <div class="card-header">
            <div class="card-title">
              <h3 class="card-label">
                <span class="svg-icon svg-icon-primary svg-icon-2x">
                  <inline-svg src="media/svg/icons/Map/Position.svg" />
                </span>
              </h3>
            </div>
            <div class="card-toolbar">
              <router-link
                to="/Lessors/list"
                class="btn btn-light-primary font-weight-bolder mr-2"
              >
                <i class="ki ki-long-arrow-back icon-sm"></i>
                All Lessors
              </router-link>
              <div class="js-submit-options btn-group">
                <button
                  @click="patchEntity"
                  type="button"
                  class="btn btn-primary font-weight-bolder"
                >
                  <i class="ki ki-check icon-sm"></i>
                  Update Lessor
                </button>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-xl-2"></div>
              <div class="col-xl-8">
                <div class="my-5">
                  <h3 class="text-dark font-weight-bold mb-10">Info:</h3>
                  <div class="form-group row">
                    <label class="col-3">{{ $t('GENERAL.NAME') }}:</label>
                    <div class="col-9">
                      <input
                        v-model="lessor.name"
                        class="form-control form-control-solid"
                        type="text"
                      />
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-3">{{ $t('MAIN_MENU.COMPANY') }}:</label>
                    <div class="col-9">
                      <input
                        v-model="lessor.company"
                        class="form-control form-control-solid"
                        type="text"
                      />
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-3">{{ $t('GENERAL.ADDRESS') }}:</label>
                    <div class="col-9">
                      <input
                        v-model="lessor.address"
                        class="form-control form-control-solid"
                        type="text"
                      />
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-3">OIB:</label>
                    <div class="col-9">
                      <input
                        v-model="lessor.oib"
                        class="form-control form-control-solid"
                        type="text"
                      />
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-3">Email:</label>
                    <div class="col-9">
                      <input
                        v-model="lessor.email"
                        class="form-control form-control-solid"
                        type="email"
                      />
                    </div>
                  </div>
                  <h3 class="text-dark font-weight-bold mb-10">Meta:</h3>
                  <div class="form-group row">
                    <label class="col-3"
                      >{{ $t('LESSORS.RENTAL_TYPE') }}:</label
                    >
                    <div class="col-9">
                      <select
                        @change="onRentalTypeChange($event)"
                        class="form-control text-capitalize"
                      >
                        <option
                          v-for="item in this.rentalTypeOptions"
                          :key="item"
                        >
                          {{ item }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div
                    v-if="
                      this.currentUserPersonalInfo.email.includes('@kidsdelux.hr') && this.currentUserRole === 'ROLE_ADMIN'
                    "
                  ></div>
                  <div
                    v-if="
                      lessor.meta && lessor.meta.rentalType !== 'percentage'
                    "
                    class="form-group row"
                  >
                    <label class="col-3"
                      >{{ $t('LESSORS.CONTRACT_RENTAL_AMOUNT') }}:</label
                    >
                    <div class="col-9">
                      <input
                        v-model="lessor.meta.rentalAmount"
                        class="form-control form-control-solid"
                        type="number"
                      />
                    </div>
                  </div>
                  <div
                    v-if="lessor.meta && lessor.meta.rentalType === 'hybrid'"
                    class="form-group row"
                  >
                    <label class="col-3"
                      >{{ $t('LESSORS.INCOME_LIMIT') }}:</label
                    >
                    <div class="col-9">
                      <input
                        v-model="lessor.meta.incomeLimit"
                        class="form-control form-control-solid"
                        type="number"
                      />
                    </div>
                  </div>
                  <div
                    v-if="lessor.meta && lessor.meta.rentalType !== 'fix'"
                    class="form-group row"
                  >
                    <label class="col-3"
                      >{{ $t('LESSORS.RENTAL_PERCENTAGE') }}:</label
                    >
                    <div class="col-9">
                      <input
                        v-model="lessor.meta.rentalPercentage"
                        class="form-control form-control-solid"
                        type="number"
                      />
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-3">Pdv:</label>
                    <div class="col-9">
                      <input
                        v-model="lessor.meta.pdv"
                        class="form-control form-control-solid"
                        type="text"
                      />
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-3"
                      >{{ $t('LESSORS.CONTRACT_START_DATE') }}:</label
                    >
                    <DatePicker
                      class="col-9"
                      v-model="lessor.meta.contractStartDate"
                    >
                      <template v-slot="{ inputValue, togglePopover }">
                        <input
                          @click="togglePopover()"
                          :value="inputValue"
                          class="form-control form-control-solid"
                          readonly
                        />
                      </template>
                    </DatePicker>
                  </div>
                  <div class="form-group row">
                    <label class="col-3"
                      >{{ $t('LESSORS.CONTRACT_END_DATE') }}:</label
                    >
                    <DatePicker
                      class="col-9"
                      v-model="lessor.meta.contractEndDate"
                    >
                      <template v-slot="{ inputValue, togglePopover }">
                        <input
                          @click="togglePopover()"
                          :value="inputValue"
                          class="form-control form-control-solid"
                          readonly
                        />
                      </template>
                    </DatePicker>
                  </div>
                  <div class="form-group row">
                    <label class="col-3"
                      >{{ $t('LESSORS.PAYMENT_INSURANCE') }}:</label
                    >
                    <div class="col-9">
                      <input
                        v-model="lessor.meta.paymentInsurance"
                        class="form-control form-control-solid"
                        type="text"
                      />
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-3"
                      >{{ $t('DEVICES.DEVICES_NUMBER') }}:</label
                    >
                    <div class="col-9">
                      <input
                        v-model="lessor.meta.devicesNumber"
                        class="form-control form-control-solid"
                        type="text"
                      />
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-3"
                      >{{ $t('COMPANIES.NOTIFICATION_EMAIL') }}:</label
                    >
                    <div class="col-9">
                      <input
                        v-model="lessor.meta.notificationEmail"
                        class="form-control form-control-solid"
                        type="email"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xl-2"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from '@/core/services/api.service'
import DatePicker from 'v-calendar/lib/components/date-picker.umd'
import { mapGetters } from 'vuex'

export default {
  name: 'LessorsEdit',
  components: {
    DatePicker,
  },
  data() {
    return {
      lessor: {
        name: null,
        company: null,
        address: null,
        oib: null,
        email: null,
        meta: {
          rentalType: null,
          contractStartDate: null,
          contractEndDate: null,
          rentalAmount: null,
          devicesNumber: null,
          pdv: null,
          notificationEmail: null,
          paymentInsurance: null,
          rentalPercentage: null,
        },
      },
      date: null,
      masks: {
        input: 'dd.MM.YYYY.',
      },
      rentalTypeOptions: [],
    }
  },
  mounted() {
    ApiService.get('lessors', this.$route.params.id).then(({ data }) => {
      this.lessor.name = data.name
      this.lessor.company = data.company
      this.lessor.address = data.address
      this.lessor.oib = data.oib
      this.lessor.email = data.email
      this.lessor.meta.rentalType = data.meta.rentalType
      this.lessor.meta.contractStartDate = data.meta.contractStartDate
      this.lessor.meta.contractEndDate = data.meta.contractEndDate
      this.lessor.meta.rentalAmount = data.meta.rentalAmount
      this.lessor.meta.devicesNumber = data.meta.devicesNumber
      this.lessor.meta.pdv = data.meta.pdv
      this.lessor.meta.notificationEmail = data.meta.notificationEmail
      this.lessor.meta.paymentInsurance = data.meta.paymentInsurance
      this.lessor.meta.incomeLimit = data.meta.incomeLimit
      this.lessor.meta.rentalPercentage = data.meta.rentalPercentage
      if (
        this.currentUserPersonalInfo.email.includes('@kidsdelux.hr') && this.currentUserRole === 'ROLE_ADMIN'
      ) {
        this.rentalTypeOptions = ['fix', 'percentage', 'hybrid']
      } else {
        this.rentalTypeOptions = ['fix', 'percentage']
      }
      if (data.meta.rentalType) {
        let index = this.rentalTypeOptions.indexOf(
          data.meta.rentalType.toLowerCase()
        )
        if (index !== -1) {
          this.rentalTypeOptions.splice(index, 1)
          this.rentalTypeOptions.unshift(data.meta.rentalType)
        }
      }
    })
  },
  computed: {
    ...mapGetters(['currentUserPersonalInfo', 'currentUserRole']),
  },
  methods: {
    onRentalTypeChange(event) {
      this.lessor.meta.rentalType = event.target.value.toLowerCase()
      this.lessor.meta.rentalAmount = ''
      this.lessor.meta.incomeLimit = ''
      this.lessor.meta.rentalPercentage = ''
    },
    patchEntity() {
      if (
        this.currentUserRole === 'ROLE_ROOT' ||
        this.currentUserRole === 'ROLE_SUPER_ADMIN' ||
            this.currentUserRole === 'ROLE_ADMIN'
      ) {
        ApiService.update('lessors', this.$route.params.id, this.lessor)
          .then(() => {
            this.$notify({
              group: 'notification',
              type: 'success',
              title: 'Lessor successfully updated in database.',
            })

            setTimeout(() => {
              this.$router.push({ name: 'lessors-list' })
            }, 500)
          })
          .catch(() => {})
      }
    },
  },
}
</script>

<style lang="scss"></style>
